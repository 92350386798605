var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "thank-you-page" },
    [
      _c(
        "v-container",
        { staticClass: "mt-5", attrs: { "fill-height": "" } },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("CategoryTitle", { attrs: { category: _vm.category } }),
              _vm.showLogin && !_vm.isAuthenticated
                ? _c("v-card-actions", { staticClass: "justify-start" }, [
                    _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              large: "",
                              depressed: "",
                              block: ""
                            },
                            on: { click: _vm.handleLogin }
                          },
                          [_vm._v(" Accedi ora ")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }