var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.category
    ? _c(
        "div",
        {
          staticClass: "category-title",
          class: {
            "w-100 d-flex flex-column justify-center text-center": _vm.centered,
            "d-flex flex-column flex-md-row": _vm.showHeaderImg
          }
        },
        [
          _c("ebsn-style", {
            attrs: { target: _vm.category, path: "category_advanced.CSS_STYLE" }
          }),
          !_vm.hideTitle
            ? [
                _vm.showHeaderImg && _vm.headerImage
                  ? _c("v-img", {
                      staticClass: "mr-sm-5",
                      attrs: {
                        src: _vm.headerImage,
                        height: _vm.height,
                        "max-width": _vm.$vuetify.breakpoint.smAndDown
                          ? "100%"
                          : "50%",
                        alt: _vm.category.name
                      },
                      on: {
                        load: function($event) {
                          _vm.loaded = true
                        }
                      }
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column flex-md-row align-center align-md-start"
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center flex-row flex-wrap",
                            class: { "justify-center": _vm.centered }
                          },
                          [
                            _vm.selectedCategory
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      to: {
                                        name: "Category",
                                        params: { pathMatch: _vm.category.slug }
                                      },
                                      exact: ""
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("$prev")])],
                                  1
                                )
                              : _vm._e(),
                            _c("ebsn-meta", {
                              style: {
                                color: _vm.$ebsn.meta(
                                  _vm.category,
                                  "category_info.COLOR"
                                )
                              },
                              attrs: {
                                target: _vm.selectedCategory
                                  ? _vm.selectedCategory
                                  : _vm.category,
                                path: "category_info.TITLE",
                                tag: "h1",
                                "default-value": _vm.selectedCategory
                                  ? _vm.selectedCategory.name
                                  : _vm.category.name
                              }
                            }),
                            _vm.count > 0 ? _c("v-spacer") : _vm._e(),
                            _vm.count > 0
                              ? _c("strong", { staticClass: "ml-4" }, [
                                  _vm._v(" " + _vm._s(_vm.count) + " prodotti ")
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.showDescription
                          ? _c("ebsn-meta", {
                              staticClass: "description",
                              attrs: {
                                target: _vm.category,
                                path: "category_info.DESCRIPTION",
                                tag: "div"
                              },
                              on: { click: _vm.clicked }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.$ebsn.meta(
                      _vm.category,
                      "category_info.DESCRIPTION_IMAGE"
                    )
                      ? _c("img", {
                          staticClass:
                            "category-description-image rounded-lg ml-md-8 mb-5 mb-md-0",
                          attrs: {
                            src: _vm.$ebsn.meta(
                              _vm.category,
                              "category_info.DESCRIPTION_IMAGE"
                            ),
                            alt: "Immagine categoria"
                          }
                        })
                      : _vm._e()
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }